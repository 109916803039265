<template>
  <!-- 开盘时间 -->
  <div class="body">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="通证配置" name="first">
        <div class="time">
          <el-form size="medium" style="width: 500px">
            <el-form-item label="转出手续费">
              <el-input v-model="value.tokenOutChange" type="number" placeholder=""></el-input>
            </el-form-item>

            <el-form-item label="转入手续费">
              <el-input v-model="value.tokenChange" type="number" placeholder=""></el-input>
            </el-form-item>

            <el-form-item label="互转手续费">
              <el-input v-model="value.tokenExChange" type="number" placeholder=""></el-input>
            </el-form-item>
             <el-form-item label="结算手续费">
              <el-input v-model="value.accountChange" type="number" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="充值CNY手续费">
              <el-input v-model="value.popChange" type="number" placeholder=""></el-input>
            </el-form-item>
            
            <el-form-item label="CNY转出时间">
              <!-- <el-time-picker
                v-model="cnyTime"
                type="date"
                placeholder="选择日期"
                 format="HH:mm:ss"
                value-format="HH:mm:ss"
                style="width:100%"
                >
              </el-time-picker> -->
               
                   <el-time-picker
                      is-range
                      v-model="cnyTime"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围"
                      format="HH:mm:ss"
                      value-format="HH:mm:ss"
                    >
                    </el-time-picker>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" @click="onTime('Token')">提交</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="交易配置" name="second">
        <div class="time">
          <el-form size="medium" style="width: 500px">
            <el-form-item label="开盘时间">
              <el-time-picker
                is-range
                v-model="times"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                format="HH:mm:ss"
                value-format="HH:mm:ss"
              >
              </el-time-picker>
            </el-form-item>

            <el-form-item label="委托交易手续费">
              <el-input v-model.number="value.buyToken" type="number" placeholder=""></el-input>
            </el-form-item>

            <el-form-item label="提现">
              <el-radio-group v-model="value.withdrawStatus">
                <el-radio :label="1">提现开启</el-radio>
                <el-radio :label="2">提现关闭</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="充值">
              <el-radio-group v-model="value.popupStatus">
                <el-radio :label="1">充值开启</el-radio>
                <el-radio :label="2">充值关闭</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="流转">
              <el-radio-group v-model="value.tokenStatus">
                <el-radio :label="1">流传开启</el-radio>
                <el-radio :label="2">流传关闭</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="是否开盘">
              <el-radio-group v-model="value.status">
                <el-radio :label="1">开盘</el-radio>
                <el-radio :label="2">未开盘</el-radio>
              </el-radio-group>
            </el-form-item>
             <el-form-item label="结算">
              <el-radio-group v-model="value.accountStatus">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="2">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" @click="onTime('transaction')">提交</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="充值配置" name="third">
        <div class="time">
          <el-form size="medium" style="width: 500px">
            <el-form-item label="最小充值额度">
              <el-input v-model="value.popUp" type="number" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="低于充值额度手续费">
              <el-input v-model="value.popChange" type="number" placeholder=""></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" @click="onTime('recharge')">提交</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="提现配置" name="fourth">
        <div class="time">
          <el-form size="medium" style="width: 500px">
            <el-form-item label="提现手续费">
              <el-input v-model="value.withdrawChange" type="number" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="提现最大额度">
              <el-input v-model="value.withdrawMaxAmount" type="number" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="提现最小额度">
              <el-input v-model="value.withdrawMinAmount" type="number" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="提现的最大笔数">
              <el-input v-model="value.withdrawMaxNum" type="number" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="提现倍数">
              <el-input v-model="value.withdrawRate" type="number" placeholder=""></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" @click="onTime('Withdrawal')">提交</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="锁仓配置" name="rock">
        <div class="time">
          <el-form size="medium" style="width: 500px">
            <el-form-item label="全部锁仓比例">
              <el-input v-model="value.closeRate" type="number" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="最低保证金">
              <el-input v-model="value.closeMinAmount" type="number" placeholder=""></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" @click="onTime('recharge')">提交</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- <h1>系统配置</h1> -->
    <!-- <div class="time">
      <el-form size="medium" style="width: 500px">
        <el-form-item label="开盘时间">
          <el-time-picker
            is-range
            v-model="times"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            format="HH:mm:ss"
            value-format="HH:mm:ss"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="通证提取手续费">
          <el-input v-model="value.tokenChange" type="number" placeholder=""></el-input>
        </el-form-item>

        <el-form-item label="通证提取最小数">
          <el-input v-model="value.tokenMin" placeholder="" type="number"></el-input>
        </el-form-item>

        <el-form-item label="提现手续费">
          <el-input v-model="value.withdrawChange" type="number" placeholder=""></el-input>
        </el-form-item>

        <el-form-item label="委托交易手续费">
          <el-input v-model.number="value.buyToken" type="number" placeholder=""></el-input>
        </el-form-item>

        <el-form-item label="充值限制价格">
          <el-input v-model="value.popUp" type="number" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="是否开盘">
          <el-radio-group v-model="value.status">
            <el-radio :label="1">开盘</el-radio>
            <el-radio :label="2">未开盘</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button type="primary" @click="onTime">提交</el-button>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: {},
      times: [],
      cnyTime:[],
      id: '',
      activeName: 'first'
    }
  },
  created() {
    this.axios.get('/admin/openClose/show', {}).then(res => {
      let arr = res.data.data[0]
      this.value = res.data.data[0]
      this.times.push(arr.openTime)
      this.times.push(arr.closeTime)
      this.cnyTime=this.value.cnyTime.split('-')
      console.log(this.value)
    })
  },
  methods: {
    onTime(type) {
      console.log(this.cnyTime)
      if (this.value.tokenChange < 0 || this.value.tokenExChange < 0 || this.value.tokenOutChange < 0) {
        this.$message({
          type: 'error',
          message: '手续费需大于0'
        })
      } else if (
        this.value.withdrawChange < 0 ||
        this.value.withdrawMaxAmount <= 0 ||
        this.value.withdrawMinAmount <= 0 ||
        this.value.withdrawMaxNum <= 0 ||
        this.value.withdrawRate <= 0||
        this.value.accountChange <= 0
      ) {
        this.$message({
          type: 'error',
          message: '请输入大于0的数字'
        })
      } else {
        this.axios
          .post('/admin/openClose/update', {
            id: this.value.id,
            openTime: this.times[0].toString(),
            closeTime: this.times[1].toString(),
            buyToken: this.value.buyToken,
            status: this.value.status,
            tokenChange: this.value.tokenChange,
            tokenExChange: this.value.tokenExChange,
            tokenOutChange: this.value.tokenOutChange,
            withdrawStatus: this.value.withdrawStatus,
            popupStatus: this.value.popupStatus,
            tokenStatus: this.value.tokenStatus,
            tokenMin: this.value.tokenMin,
            withdrawChange: this.value.withdrawChange,
            popChange: this.value.popChange,
            popUp: this.value.popUp,

            withdrawMaxNum: this.value.withdrawMaxNum,
            withdrawMaxAmount: this.value.withdrawMaxAmount,
            withdrawMinAmount: this.value.withdrawMinAmount,
            withdrawRate: this.value.withdrawRate,
            accountChange:this.value.accountChange,
            accountStatus:this.value.accountStatus,
            closeRate: this.value.closeRate,
            closeMinAmount: this.value.closeMinAmount,
            cnyTime:this.cnyTime[0]+'-'+this.cnyTime[1],
            popChange:this.value.popChange
          })
          .then(res => {
            if (res.data.code == 200) {
              if (type == 'Token') {
                this.$message({
                  type: 'success',
                  message: '通证配置修改成功'
                })
              }
              if (type == 'transaction') {
                this.$message({
                  type: 'success',
                  message: '交易配置修改成功'
                })
              }

              if (type == 'recharge') {
                this.$message({
                  type: 'success',
                  message: '充值配置修改成功'
                })
              }

              if (type == 'Withdrawal') {
                this.$message({
                  type: 'success',
                  message: '提现配置修改成功'
                })
              }
            } else {
              this.$message({
                type: 'error',
                message: res.data.mesg
              })
            }
          })
      }
    },
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  padding: 10px;
}
// 开盘时间
.time {
  margin: 50px 0 20px 0;
}
</style>
